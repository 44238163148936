/* .header {
  height: 15vh;
  box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.navlink {
  padding: 25px;
  width: 100%;
}
header ul li button {
  background-color: #119dd4;
  padding: 13px;
  width: 200px;
  margin-left: 50px;
  color: #fff;
  border: none;
}
header ul li button:hover {
  background-color: #0f3460;
}
header ul li img {
  width: auto;
  height: 45px;
  margin-left: 40px;
}
header ul {
  line-height: 8vh;
  display: flex;
}

header ul li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 14px;
}
header ul li a {
  transition: 0.5s;
  font-size: 15px;
  font-weight: 400;
  list-style: none !important;
  color: #3a77bd;
}
header ul li a:hover {
  color: #e94560;
}
.open {
  display: none;
} */

/*------head-----------*/
.head {
  background-color: #0f3460;
  padding: 10px 0;
  color: #fff;
}
.head i {
  margin-right: 10px;
}
.head label {
  margin-right: 30px;
  font-size: 13px;
}
.RText {
  text-align-last: right;
}
.RText .facebook {
  margin: 0 40px 0 15px;
  /* font-size: 20px; */
}
.RText .linkedin:hover {
  background-color: #119dd4;
  border-radius: 50%;
}
.RText .facebook:hover {
  background-color: #119dd4;
  /* width: 20px;
    height: 20px;
    padding: 3px; */
  overflow: hidden;
  border-radius: 50%;
}
.RText .search {
  transform: rotateY(180deg);
  font-size: 19px;
  text-align: center;
  color: #fff;
  padding-top: 3px;
}
.searching{
  width: 50px;
  height: 10px;
  border: none;
  background-color: yellow;
  color: red;
  z-index: 99;
  transition: 200ms ease-in;
}

/* .RText i{
    color: #fff;
  } */
/* .row {
    width: 50%;
  } */

/* #------------ */
/* .navbtn{

  } */
/* #-------- */
.applynow h3 {
  background-color: rgb(255, 218, 7);
  font-weight: 100;
  width: 100%;
  padding: 20px;
  justify-content: center;
  display: flex;
}

/* header ul li .subnav {
  position: absolute;
  top: 70px;
  left: 0;
  line-height: 40px;
  background: #3e8da8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
}
header ul li .subnav li {
  padding: 0 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
header ul li .subnav a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
} */

/* #----------------sub dropdown */
/* .navbar {
  height: 15vh;
  box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.2);
  background-color: rgb(233, 17, 17);
  color: chartreuse;
} */

/* .navlink {
  padding: 25px;
  width: 100%;
} */
/* nav{
  height: 200vh;
  box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.2);
  background-color: rgb(233, 17, 17);
  color: chartreuse;
} */

.logo img{
  width: auto;
  height: 45px;
  margin-left: 90px;
}
 .services-submenu {
  position: absolute;
  top: 94px;
  list-style: none;
  box-shadow: 0px -10px 20px -15px rgba(0, 0, 0, 0.2);
  text-align: start;
  z-index:1;
  width: 15rem;

}
/* nav ::after{
   content: none !important;


} */

.services-submenu li {
  /* background: rgb(0, 212, 212); */
  background-color: #fff;
  cursor: pointer;
  padding: 5px;
}
.services-submenu li a{
  color: black;
  transition: none;
  font-size: 12px;
  /* padding: 10px; */

  /* max-width: 100%; */
}
.services-submenu li a:hover {
  background: rgba(0, 175, 175, 0.075);
  /* border-radius: 10px; */

}

.services-submenu.clicked {
  display: none;
}

.submenu-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 16px;
  
}

.navbar {
  height: 15vh;
  background: #fff;
  display: flex;
  justify-content: center;
  
  align-items: center;
  box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.2);
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 10rem;s */
}

.nav-items {
  display: flex;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: flex-end;
  
  /* margin-right: 2rem; */
}

.nav-item {
  display: flex;
  align-items: center;
  height: 15vh;
  position: relative;

}

.nav-item a {
  /* transition: ease-in-out 0.5s; */
  text-decoration: none;
  /* color: white; */
  color: #3a77bd;
/* height: fit-content; */
  /* font-size: 1.0rem; */
  font-size: 14px;
  margin-right: 1rem;
  /* margin-left: 1rem; */
  padding: 10px 10px;
  border-radius: 5px;
  
}::after{
  content: "";
  width: 0;
  display: block;
  height: 3px;
  position: absolute;
  /* left: 1rem; */
  bottom: 2rem;
  background-color:rgb(0, 187, 187);
  transition: width 200ms ease-in-out;
} 

.nav-item a:hover {
  color: rgb(0, 212, 212);
} 

/* .nav-item :hover::after{
  width: 80%;
  

} ::after{
  content: "";
  width: 0;
  display: block;
  height: 3px;
  position: absolute;
  bottom: 2rem;
  background-color:rgb(0, 187, 187);
  transition: width 200ms ease-in-out;
}  */

.nav-item>a:hover::after , .nav-item>a:focus::after {
  width: 80%;

}

/* #--------- respomsive */
@media screen and (max-width: 844px) {

*{
  /* width: auto;
  height: auto; */
}

  h1 , h2 , h3 , h4 , h5{
    text-align: center;
    width: fit-content;
    height: fit-content;
  }
  img{
    width: fit-content;
    height: fit-content;
  }
  .navbar{
    /* width: fit-content; */
    /* height: fit-content; */
    width: auto;
    height: auto;
  }
  .logo{
    height: fit-content;
  }
  .nav-item{
    display: none;
  }
  button{
    /* width: fit-content;
    height: fit-content;
    width: auto; */
  }
}