.Stext {
  /* padding: 100px; */
  margin: 100px;
  left: 100px;
}
.Stext h4 {
  color: #0f4381;
  /* margin-top: 20px; */
  /* padding: 100px; */
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}
.Stext h5 {
  opacity: 0.8;
  font-size: 19px;
  /* padding: 70px; */

  font-weight: 500;
  margin-top: 50px;
  text-align: start;
}
.Stext h1 {
  color: #0f3460;
  /* font-weight: bolder; */
  font-size: 33px;
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: center;
}
.Sbtn button {
  background-color: rgba(177, 2, 2, 0.87);
  padding: 20px;
  color: #fff;
  border: none;
  width: 300px;
  font-size: 17px;
  font-weight: 600;
}
.Sbtn{
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
}
.Stext h4 a{
  color: #136cda;
}
.Stext h4 a:hover{
 text-decoration: underline;
}
.Stext h5 a{
  color: #136cda;
}
.Stext h5 a:hover{
 text-decoration: underline;
}

