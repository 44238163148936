.btn {
  /* border: none; */
  /* background: transparent; */
  /* padding: 6px 16px; */
  /* font-size: 1.1rem; */
  /* color: white; */
  /* background: rgb(0, 212, 212); */
  /* border-radius: 5px; */


  transition: 0.3s all ease-out;


  cursor: pointer;

  background-color: #119dd4;
  padding: 12px;
  width: 200px;
  margin-right: 90px;
  margin-left: 70px;
  color: #fff;
  border: none;
}

.btn:hover {
  background-color: #0f3460;
  
}
