/* .home{
    margin-top: 20px;
} */
/* .contentWidth {
    width: 100%;
  } */
  .containerr{
    margin: 0;
     /* padding: 40px; */
     /* width:1340px; */
     /* width:98%; */
     /* height: 70%; */
     color: rgb(49, 49, 49);
   }

.homeSLide{
  /* width: 80%; */
  /* overflow: hidden; */

}
.homeSlide h1{
    font-size: 45px;
  
}
.homeSlide p{
 position: absolute;       
 bottom: 20px;
}
.homeSlide ul {
    bottom: 0;
    /* margin-top: 100px; */
}
.homeSlide ul li{
    border: 2px solid #b8b7b7;
    border-radius: 50%;
    padding: 10px;
}
.homeSlide ul li button{
    background: transparent !important;
    color: transparent !important;

}
.homeSlide ul li button::before{
    font-size: 10px;
    color: darkblue;
    position: absolute;
    top: 2px;
    left: 0;
    margin: auto;
    
}
.homeSlide .box{
    display: flex !important;
    width: 100%;
}
/* .homeSlide .box .left{
    width: 70% !important;
    
}
.homeSlide .box .right{
    width: 30% !important;
} */
.Slidecentered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Slidecentered h1 {
  color: #fff;
  font-weight: 700;
  font-size: 80px;
  text-shadow: 1px 1px #3d3d3d;
  text-align: center;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* justify-content: baseline; */
  /* -webkit-text-stroke: medium; */
}
.Slidecentered h5 {
  font-size: 19px;
  /* padding: 70px; */
color: #fff;
  font-weight: 500;
  /* margin-top: 50px; */
  text-align: center;
  text-shadow: 1px 1px #3d3d3d;

}
.Slidecentered button{
  background: none;
  padding: 20px;
  color: #fff;
  border: 2px solid #fff;
  width: 300px;
  font-size: 17px;
  font-weight: 600;
}
/* .homeSlide .box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.homeSlide .box img{
    /* width: 100%;
    height: 50%; */
    /* width: 1349px; */
    height: 66vh;
    width: 100%;
    /* height: 100%; */
    /* position: absolute; */
    /* left: 0;
    top: 0;
    overflow: hidden; */
    object-fit: cover;
    /* object-fit: contain; */
    overflow: hidden; 
}
/* .homeSlide .contentWidth{
    width: 80%;
    margin: 0;
    color: teal;
    background-color: yellow;
} */

/* #-----------about home pages */
.dis{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.rightt{
    /* width: 50%; */
    /* float: left; */
padding: 70px;
/* margin-top: 100px; */
 }
 .rightt ul li {
    margin-top: 30px;
 }

 .rightt button {
    text-transform: uppercase;
    background-color: #119dd4;
    padding: 13px;
    width: 200px;
    color: #fff;
    border: none;
    /* float: left; */
  }
  .rightt button:hover{
    background-color: #0f3460;
  }
  .rightt h1{
/* color: #119dd4; */
color: #0f3460;
font-weight: bolder;
font-size: 50px;
margin-top: 70px;
-webkit-text-stroke-width: thin;

  }
.rightt h3{
    font-weight: 300;
    opacity: 0.7;

}
/* ####_____________ */
.find{
    position: relative;
    text-align: center;
    color: rgb(0, 0, 0);
}
.find img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}
.find .centered {
    position: absolute;
    top: 30%;
    width: 35%;
    text-align: start;
    margin-left: 60px;
    /* transform: translate(-50%, -50%); */
  }
  
  .find .centered ul li button{
    text-transform: uppercase;
    background-color: #119dd4;
    padding: 13px;
    width: 200px;
    left: 0;
    color: #fff;
    border: none;
  }
  .find .centered ul li button:hover{
    background-color: #0f3460;
  }
  .find .centered ul li  {
color: #fff;
margin-top: 20px;
  }
  .find .centered ul li h1 {
    font-weight: 700;
    font-size: 50px;
   -webkit-text-stroke: thin;
    /* margin-left: 50px; */
  }
  .find .centered ul li h3{
    font-weight: 300;
  }
  .leftt img{
    width: 100%;
    object-fit: cover;
  }
  /* .find .fltr img{
    filter: grayscale(50%);
  } */



  
/* #--------- respomsive */
@media screen and (max-width: 763px) {

  *{
    /* width: auto;
    height: auto; */
  }
  
    h1 , h2 , h3 , h4 , h5{
      text-align: center;
      width: fit-content;
      height: fit-content;
      font-size: medium;
    }
    img{
      width: fit-content;
      height: fit-content;
      object-fit: cover;
    }
    button{
      /* width: fit-content;
      height: fit-content;
      width: auto; */
    }
    .leftt img{
      display: none;
    }
    .rightt ul li{
      text-align: center;
      width:auto;
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }